import React, { useEffect, useState } from "react";
import { Column, Columns, Container } from "bloomer";
import Carousel, { consts } from "react-elastic-carousel";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import {
  ContOurPlans,
  ContCard,
  StylesOurPlans,
  ContOurPlansCards,
  ContNocuota,
  TextOrange,
} from "./styles";
import { PieTitleSection, TitleSection, TitleSectionH1 } from "../../styles/GlobalStyles";
import { CardPlan } from "../CardPlan";
import { CardPlantwo } from "../CardPlantwo";
import { CardPlanthree } from "../CardPlanthree";
import { TransitionState } from "gatsby-plugin-transition-link";

export const OurPlans = ({
  inAllsLine = false,
  spaceTop = true,
  spaceBottom = true,
  link = "",
  data = false,
}) => {
  const [screenCards, setScreenCards] = useState();
  const [personalData, setPersonalData] = useState({
    name: "",
    last_name: "",
    second_lastname: "",
    email: "",
    phone: "",
  });
  const { height, width } = useWindowDimensions();
  const [services1, setServices1] = useState([
    {
      label: "30 llamadas entrantes o salientes",
      value: false,
    },
    {
      label: "30 notificaciones",
      value: false,
    },
    {
      label: "Llamada adicional: $19.00 MXN",
      value: false,
    },
    {
      label: "Sin opción de agenda",
      value: false,
    },
  ]);
  const [services2, setServices2] = useState([
    {
      label: "60 llamadas entrantes o salientes",
      value: false,
    },
    {
      label: "60 notificaciones",
      value: false,
    },
    {
      label: "Llamada adicional: $17.00 MXN",
      value: false,
    },
    {
      label: "Agenda adicional: $19.00 MXN",
      value: false,
    },
    //{
    //  label: "Sin opción a la llamada saliente para confirmación de citas",
    //  value: false,
    //},
  ]);
  const [services3, setServices3] = useState([
    {
      label: "100 llamadas entrantes o salientes",
      value: false,
    },
    {
      label: "100 notificaciones",
      value: false,
    },
    {
      label: "Llamada adicional: $13.00 MXN",
      value: false,
    },
    {
      label: "Agenda adicional: $19.00 MXN",
      value: false,
    },
    //{
    //  label: "Llamada saliente: $62.00 MXN",
    //  value: false,
    //},
  ]);
  const [stateAux, setStateAux] = useState();

  const myArrow = () => {
    return <div />;
  };

  useEffect(() => {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) {
      const ancho = window.innerWidth <= 1023 ? 0 : 1;
      setScreenCards(ancho);
    }
    
  }, [width]);

  useEffect(() => {

    const arData = {
      name: data.name,
      last_name: data.last_name,
      second_lastname: data.second_lastname,
      email: data.email,
      phone: data.phone,
    };

    // console.log('arData =>', arData);
    setPersonalData(arData);
  }, [data]);

  return (
    <ContOurPlans id={"ourplans"}>
      <StylesOurPlans />
      <TransitionState>
        {(state) => {
          //console.log('TransitionState -> state ->', state);
          if (state.current.state) {
            setStateAux(state.current.state);
          }
        }}
      </TransitionState>
      <Container isFluid={true}>
        <Columns className={"full-height"} isMultiline={true}>
          <Column
            className={"no-display-mobile"}
            isSize={{ mobile: 0, tablet: 0, desktop: 2, widescreen: 2 }}
          >
            &nbsp;
          </Column>
          <Column
            className={"center-column"}
            isSize={{ mobile: 12, tablet: 12, desktop: 8, widescreen: 8 }}
          >
            <Columns className={"full-height"} isMultiline={true}>
              <Column
                isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}
              >
                <TitleSectionH1 className={"animate__animated animate__fadeInUp"}>
                  Nuestros <span>Planes</span>
                </TitleSectionH1>
                <Column
                  isSize={{
                    mobile: 12,
                    tablet: 12,
                    desktop: 12,
                    widescreen: 12,
                  }}
                >
                  <PieTitleSection
                    className={"animate__animated animate__fadeInDown"}
                  >
                    <div>¿Aún no estás convencido?</div>
                    <div>
                      Echa un vistazo a estas opciones y encuentra el plan
                      perfecto para ti.
                    </div>
                  </PieTitleSection>
                </Column>
              </Column>
            </Columns>
          </Column>
          <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
            <ContNocuota>
              <TextOrange>¡Ahora sin cuota de alta!</TextOrange>
            </ContNocuota>
          </Column>
        </Columns>

        <ContOurPlansCards id={'ContOurPlansCards'} spaceTop={spaceTop} spaceBottom={spaceBottom}>
          <Columns className={"full-height"} isMultiline={true}>
            <Column
              className={"one-row"}
              isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}
            >
              {screenCards ? (
                <>
                  <ContCard>
                    <CardPlan
                      type={1}
                      name={"pro"}
                      title={"Pro"}
                      subTitle={"¡Contrata a tu asistente personal!"}
                      info1={"30 llamadas entrantes o salientes"}
                      info2={"30 notificaciones"}
                      info3={"Llamada adicional: $19.00 MXN"}
                      info4={"Sin opción de agenda"}
                      info5={""}
                      info6={""}
                      icon5={0}
                      price={"499"}
                      link={link}
                      service={services1}
                      personalData={personalData}
                    />
                  </ContCard>
                  <ContCard>
                    <CardPlantwo
                      type={1}
                      name={"premium"}
                      title={"Premium"}
                      subTitle={"¡Lleva tu servicio al siguiente nivel!"}
                      info1={"60 llamadas entrantes o salientes"}
                      info2={"60 notificaciones"}
                      info3={"Llamada adicional: $17.00 MXN"}
                      info4={"Agenda adicional: $19.00 MXN"}
                      //info5={
                      //  "Sin opción a la llamada saliente para confirmación de citas"
                      //}
                      info6={""}
                      price={"899"}
                      link={link}
                      service={services2}
                      personalData={personalData}
                    />
                  </ContCard>
                  <ContCard>
                    <CardPlanthree
                      type={1}
                      name={"plus"}
                      title={"Plus"}
                      subTitle={"¡Olvídate de los límites!"}
                      info1={"100 llamadas entrantes o salientes"}
                      info2={"100 notificaciones"}
                      info3={"10 agendas"}
                      info4={"Llamada adicional: $13.00 MXN"}
                      info5={"Agenda adicional: $19.00 MXN"}
                      //info6={"Llamada saliente: $62.00 MXN"}
                      price={"1,199"}
                      link={link}
                      service={services3}
                      personalData={personalData}
                    />
                  </ContCard>
                </>
              ) : (
                <Carousel
                  isRTL={false}
                  itemsToScroll={1}
                  itemsToShow={1}
                  itemPadding={[0, 0]}
                  renderArrow={myArrow}
                  outerSpacing={0}
                  itemPosition={consts.START}
                >
                  <ContCard>
                    <CardPlan
                      type={1}
                      name={"pro"}
                      title={"Pro"}
                      subTitle={"¡Contrata a tu asistente personal!"}
                      info1={"30 llamadas entrantes o salientes"}
                      info2={"30 notificaciones"}
                      info3={"Llamada adicional: $19.00 MXN"}
                      info4={"Sin opción de agenda"}
                      info5={""}
                      info6={""}
                      icon5={0}
                      price={"499"}
                      link={link}
                      service={services1}
                      personalData={personalData}
                    />
                  </ContCard>
                  <ContCard>
                    <CardPlantwo
                      type={1}
                      name={"premium"}
                      title={"Premium"}
                      subTitle={"¡Lleva tu servicio al siguiente nivel!"}
                      info1={"60 llamadas entrantes o salientes"}
                      info2={"60 notificaciones"}
                      info3={"Llamada adicional: $17.00 MXN"}
                      info4={"Agenda adicional: $19.00 MXN"}
                      //info5={
                      //  "Sin opción a la llamada saliente para confirmación de citas"
                      //}
                      info6={""}
                      price={"899"}
                      link={link}
                      service={services2}
                      personalData={personalData}
                    />
                  </ContCard>
                  <ContCard>
                    <CardPlanthree
                      type={1}
                      name={"plus"}
                      title={"Plus"}
                      subTitle={"¡Olvídate de los límites!"}
                      info1={"100 llamadas entrantes o salientes"}
                      info2={"100 notificaciones"}
                      info3={"10 agendas"}
                      info4={"Llamada adicional: $13.00 MXN"}
                      info5={"Agenda adicional: $19.00 MXN"}
                      //info6={"Llamada saliente: $62.00 MXN"}
                      price={"1,199"}
                      link={link}
                      service={services3}
                      personalData={personalData}
                    />
                  </ContCard>
                </Carousel>
              )}
            </Column>
          </Columns>
        </ContOurPlansCards>
      </Container>
    </ContOurPlans>
  );
};
