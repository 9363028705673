import * as React from "react"
import 'animate.css';
import Layout from '../components/Layout'
import SEO from "../components/seo"
import {Home} from '../components/Home';
import {InfoBubble} from '../components/InfoBubble';
import {ConfigPlan} from '../components/ConfigPlan';
import {Why} from '../components/Why';
import {Solutions} from '../components/Solutions';
import {OurPlans} from '../components/OurPlans';
import {Faqs} from '../components/Faqs';

import ContactComp from '../components/Contact';

const IndexPage = () => {
  return (
    <Layout clsOverflow={false} typeRouteMenu={'#'}>
      <SEO
        title=""
        description=""
        works=''
        bolImage='1'
        ogImage={`home_f.jpg`}
        twitterImage={`home_t.jpg`}
        canonicalUrl="https://www.callmatik.com/"
      />

      <Home/>
      <InfoBubble/>
      <Solutions/>
      <OurPlans/>
      <Why/>
      <ConfigPlan/>
      <Faqs/>
      <ContactComp/>
    </Layout>
  )
}

export default IndexPage
