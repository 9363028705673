import React, { useState } from 'react';
import {
  ContAccordion,
  ContAccordionItem,
  ContAccordionItemInner,
  ContIcon,
  ContName,
  ContSubItem, DataInfo, DataName,
} from './styles';
import IconArrowRight from './ico-arrow-right.svg';
import IconArrowDown from './ico-arrow-down.svg';

export const AccordionMyS = ({ data, limit = data.length }) => {
  const [stData, setStData] = useState(data.slice(0, limit));

  const fnToggleLevels = index => {
    let arStates = stData.slice();
    arStates[index].show = !arStates[index].show;
    setStData(arStates);
  };

  return (
    <ContAccordion>
      {stData.map((item, index) => {
        return (
          <ContAccordionItem key={index}>
            <ContAccordionItemInner onClick={() => fnToggleLevels(index)}>
              {/*<ContIcon>
                <img src={item.icon} alt={''}/>
              </ContIcon>*/}
              <ContName>
                <DataName>{item.dataFirst}</DataName>
              </ContName>
              <ContIcon>
                <img src={item.show ? IconArrowDown : IconArrowRight} alt={''} />
              </ContIcon>
            </ContAccordionItemInner>

            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataSecond}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataThird}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataQuarter}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataFive}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataSix}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataSeven}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataEight}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataNine}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataTen}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataEleven}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataTwelve}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataThirteen}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataFourteen}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataFiveteen}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataSixteen}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataSeventeen}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataEightteen}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataNineteen}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
            {item.show && (
              <ContSubItem>
                <ContName>
                  <DataInfo>
                    {item.dataTwenty}
                  </DataInfo>
                </ContName>
              </ContSubItem>
            )}
          </ContAccordionItem>
        );
      })}
    </ContAccordion>
  );
};
