import styled, { createGlobalStyle } from 'styled-components'
import {GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const StylesInfoBubble = createGlobalStyle`
  
`
export const ContInfoBubble = styled.div`
  width: 100%;
  margin-top: 10px;
  margin-bottom: 50px;
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-top: 0;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-top: 0;
  }
`
export const ContInfoBubbleInner = styled.div`
  width: 100%;
  padding: 15px 0;
  background: #FFFFFF;
  box-shadow: 0px 8px 28px -6px rgba(124, 117, 201, 0.2), 0px 18px 88px -4px rgba(124, 117, 201, 0.2);
  border-radius: 20px;
`
export const ContText = styled.div`
  width: 100%;
`
export const ContText1 = styled.div`
  width: 100%;
  font: 24px ${GlobalFonts.fontBold};
  font-style: normal;
  font-weight: 800;
  line-height: 28px;
  color: ${GlobalColors.colorPrimary};
  
  &>span{
    color: ${GlobalColors.colorSecondary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 14px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 16px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 18px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 20px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 22px;
  }
  @media(min-width: 1920px){
    font-size: 24px;
  }
`
export const ContText2 = styled.div`
  width: 100%;
  font: 18px ${GlobalFonts.fontPrimary};
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  color: ${GlobalColors.colorText};

  @media(min-width: 300px) and (max-width: 767px){
    font-size: 12px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 14px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 16px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 16px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 18px;
  }
  @media(min-width: 1920px){
    font-size: 18px;
  }
`
