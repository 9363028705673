import React, {useEffect} from 'react';
import {
  ContInput,
  ContTextArea,
  Label,
  ErrorText,
  TextArea,
  Text,
} from './styles';
import {useInputText} from './useTextArea';
import {GlobalColors, GlobalColorsInputText} from '../../../styles/appColors';
import {GlobalBackgroundColors} from '../../../styles/GlobalStyles';

export const TextAreaMyS = ({
   name,
   label,
   value,
   requiredField = false,
   keyboardTypePassword = false,
   onChange,
   secureTextEntry,
   maxLength,
   validateInput = true,
   statusInput,
   height = '56px',
   backgroundColor = GlobalColorsInputText.primaryBg,
   borderColor = GlobalColorsInputText.labelFocus,
   fontSize = '16px',
   fontColor = GlobalColors.text,
   paddingHorizontal = '12px',
   textAlign = '',
   showLabel = true,
   labelColor = GlobalColorsInputText.labelFocus,
   autoFocus = false,
   forceFocus = false,
   readOnly = true,
   copyValue = false,
   ref,
 }) => {
  const {
    onFocus,
    onBlur,
    showError,
    error,
    top,
    topFocus,
    topBlur,
    focus,
  } = useInputText(
    name,
    value,
    requiredField,
    validateInput,
    statusInput,
  );

  useEffect(() => {
    // console.log('InputTextMyS -> value ->', value);
    if (forceFocus) {
      onFocus();
    }
  }, []);

  // console.log('InputTextMyS -> value ->', value);
  // console.log('InputTextMyS -> focus ->', focus);

  return (
    <ContTextArea
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      height={height}
      marginBottom={showError ? '25px' : '16px'}
      paddingHorizontal={paddingHorizontal}>
      {showLabel && (
        // <Label translateY={focus ? top : '50px'}>
        <Label
          bg={focus || value ? GlobalBackgroundColors.bgPrimary : 'transparent'}
          translateY={focus || value ? topFocus : topBlur}
        >
          <Text
            color={focus || value ? labelColor : GlobalColorsInputText.label}>
            {' '}{label}{' '}
          </Text>
        </Label>
      )}
      <ContInput>
        <TextArea
          fontSize={fontSize}
          color={fontColor}
          textAlign={textAlign ? textAlign : 'left'}
          name={name}
          value={value}
          onChange={onChange}
          onFocus={() => onFocus()}
          onBlur={() => onBlur()}
          maxLength={maxLength}
          autoFocus={autoFocus}
          readOnly={readOnly}
          ref={ref}
        />
      </ContInput>
      {showError ? <ErrorText>{error}</ErrorText> : null}
    </ContTextArea>
  );
};
