import styled from 'styled-components'
import {GlobalColors, GlobalFonts} from '../../../styles/GlobalStyles';

export const ContAccordion = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const ContAccordionItem = styled.div`
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid ${GlobalColors.colorPrimary};
  display: flex;
  flex-direction: column;
`
export const ContAccordionItemInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`
export const ContIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ContName = styled.div`
  width: 100%;
  height: 100%;
  //padding-left: 15px;
  align-items: flex-start;
  justify-content: center;
`
export const ContSubItem = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 15px;
`
export const DataName = styled.div`
  font-family: ${GlobalFonts.fontExtraBold};
  font-size: 24px;
  text-align: left;
  color: ${GlobalColors.colorPrimary};
`
export const DataInfo = styled.div`
  font-family: ${GlobalFonts.fontRegular};
  font-size: 18px;
  text-align: left;
  color: ${GlobalColors.colorText};
`
