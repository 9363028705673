import React, { useState } from 'react';
import { Column, Columns, Container } from 'bloomer';
import { PieTitleSection, TitleSection } from '../../styles/GlobalStyles';
import { ContFaqs, ContBtn } from './styles';
import { AccordionMyS } from '../ui/Accordion';
import {ABtnPrimaryTwo} from '../../styles/GlobalStyles';

const arFaqs = [
  {
    icon: null,
    dataFirst: '¿Cómo puede ayudarme CallMatik?',
    dataSecond: 'CallMatik ha sido desarrollado para ayudar a cualquier Empresa y Profesionales Independientes,  a crecer como negocio. Resolviendo la atención de llamadas y programación de agenda de forma profesional y eficiente.',
    dataThird: 'Callmatik te permitirá liberarte de tareas administrativas para poder centrarte en tu negocio. Además el sistema de Callmatik te permite ahorrar gastos de contratación, gestión, recursos, etc.',
    dataQuarter: 'Solo por $499 MXN más IVA al mes (precio del programa PRO) podrás llevar tu negocio a otro nivel. Atendiendo las llamadas de tus clientes con un trato totalmente personalizado, como si hablaran con tu asistente en tu oficina, haciendo tu vida más fácil. Tendrás mucho más tiempo para dedicar a tu negocio y tener momentos de calidad con tu familia y amigos. Disfrutar de una comida, una película, un partido o ganar tiempo de sueño y descanso sin el estrés de las llamadas.',
    dataFive: 'No esperes más y prueba callmatik. Tu negocio, familia, amigos y sobre todo tú lo agradecerás.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Cómo funciona CallMatik?',
    dataSecond: 'Contamos con secretarias profesionales a tu disposición para atender las llamadas que necesites de la forma en que lo requieras.',
    dataThird: '1.- Se te asigna un número telefónico único, el cual podrás compartir con tus clientes o simplemente transferir las llamadas desde tu teléfono actual.',
    dataQuarter: '2.- Al ingresar una llamada contestaremos amablemente con el saludo de tu empresa, de la forma que tu nos indiques, como si estuviéramos en tu oficina.',
    dataFive: '3.- Aclaramos dudas, agendamos citas o transferimos llamadas.',
    dataSix: '4.- Te enviamos mensaje de todas tus llamadas. Y adicionalmente tendrás un reporte de todas las gestiones.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Horarios?',
    dataSecond: 'Nuestro horario de atención a tus llamadas es de 8:00 a 20:00 horas, ininterrumpido de lunes a viernes.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Cómo puedo contratar?',
    dataSecond: 'Puedes contratarlo via web en www.callmatik.com. de forma on-line en pocos minutos.',
    dataThird: 'Con solo dos pasos: uno,  validar tu e-mail  dos, seleccionar el plan que más se ajuste a tus necesidades.',
    dataQuarter: 'Inicialmente se realiza un cargo de $ 5.00  MXN  para verificar el medio de pago.',
    dataFive: 'Y ya contarás con tu servicio,  un numero asignado y acceso para darnos tus instrucciones y visualizar tu panel de control en un área privada para ti.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Cómo realizo los pagos del servicio?',
    dataSecond: 'En el momento del registro te pediremos un número de tarjeta de crédito o débito. Los cargos se realizarán mensualmente sobre esta tarjeta.',
    dataThird: 'Inicialmente se realiza un cargo de $ 5.00  MXN  para verificar el medio de pago.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Puedo personalizar como quiero que atiendan mis llamadas?',
    dataSecond: 'Claro. Al contratar el servicio tienes acceso a tú panel personalizado de gestión de servicio. En tu panel puedes indicar desde al saludo inicial, indicaciones las características de tu negocio, las preguntas que deben hacer, que información solicitar, horarios, instrucciones de desvío, de gestión de calendario, hasta temas tan concretos como que estas de vacaciones, mudanza, etc.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Cómo puedo modificar mi información?',
    dataSecond: 'Una vez que realices la contratación del servicio contarás con tu usuario y clave para acceder a tu área privada siempre que lo necesites. En tu área privada veras entre otras cosas tu panel personalizado de gestión, donde cuando quieras podrás hacer tus modificaciones. Sin ningún costo extra.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿En caso de dudas sobre el servicio como puedo contactar con vosotros?',
    dataSecond: 'Estaremos encantados de atenderte desde este enlace de contacto o a través del número de teléfono +52 556 599 5400.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Cuánto tarda en activarse el servicio?',
    dataSecond: 'Después de completar tu pedido, te asignamos tu número único de servicio de CallMatik. Y desde ese momento ya podrás disfrutar de sus beneficios.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Cómo activo el servicio de desvío de llamadas?',
    dataSecond: 'Marca en tu celular o fijo *21*TúNúmeroCallMatik#.',
    dataThird: 'Ejemplo: Si tu número Callmatik fuera por ejemplo: 1234512345',
    dataQuarter: 'ACTIVAR, marcar *21*1234512345# y pulsar llamada 📞.',
    dataFive: 'DESACTIVAR, sólo marca #21# y pulsar llamada 📞.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Cómo puedo ver que llamadas he tenido?',
    dataSecond: 'Siempre que quieras podrás acceder a tu área privada para ver con todo detalle la actividad de tu servicio. También te enviaremos un e-mail con cada gestión de llamada.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Puedo transferir llamadas a otros números?',
    dataSecond: 'Sí, sin problema, debes identificarlo en tu panel de gestión de servicio. Podrás indicar en qué casos y a quien debemos desviar la llamada. Pondremos la llamada entrante en espera hasta verificar que se quiere responder.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Puedo cambiar mi plan?',
    dataSecond: 'Sí, puedes realizar la solicitud de cambio a través de tu acceso privado. Los cambios se realizarán para el siguiente mes de facturación.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Qué pasa tras consumir el pack de llamadas entrantes de mi tarifa?',
    dataSecond: 'Puedes seguir disfrutando del servicio. Las llamadas adicionales se cobrarán según los precios de llamada adicional de tu tarifa.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Qué pasa si no utilizo mi pack de llamadas mínimo?',
    dataSecond: 'El pack es solo para el mes.  El sistema pone el contador a "0" cada mes. No son acumulables',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Cómo funciona la gestión de agenda?',
    dataSecond: 'El servicio personalizado permite el servicio de agenda. Estaremos sincronizados con tu agenda para ver tu disponibilidad, tus indicaciones para registrar, modificar o cancelar  reuniones. Compatible con cualquier agenda  con Google Calendar.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Qué pasa tras consumir el pack de agenda de mi tarifa?',
    dataSecond: 'Puedes seguir disfrutando del servicio de agenda. Los apuntes adicionales se cobrarán según los precios de apunte agenda adicional de tu tarifa.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Consulta y descarga de facturas?',
    dataSecond: 'A través de tu área privada en la web podrás consultar y descargar tus facturas.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Puedo cancelar el servicio cuando quiera?',
    dataSecond: 'Claro. Solo deberás notificarnos con 10 días de anticipación a través del teléfono +52 556 599 5400',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Qué tipos de desvío puedo realizar?',
    dataSecond: 'Activar para todas tus llamadas entrantes:',
    dataThird: 'Marca en tu celular o fijo *21*TúNúmeroCallMatik#.',
    dataQuarter: 'Ejemplo: Si tu número Callmatik fuera por ejemplo: 1234512345',
    dataFive: 'ACTIVAR, marcar *21*1234512345# y pulsar llamada 📞.',
    dataSix: 'DESACTIVAR, sólo marca #21# y pulsar llamada 📞.',
    dataSeven: 'CONSEJO: Guarda estos dos códigos en tus contactos. Ejemplo: ASISTENTE ACTIVAR con número *21*TúNúmeroCallMatik# ASISTENTE DESACTIVAR con número #21#” ASISTENTE DIRECTO con TúNúmeroCallMatik Y te será mucho más sencillo Activar, Desactivar, o llamar a tu asistente CallMatik.',
    dataEight: '',
    dataNine: 'Activar solo cuando tienes el teléfono ocupado:',
    dataTen: 'Marca en tu celular o fijo *67*TúNúmeroCallMatik#.',
    dataEleven: 'Ejemplo: Si tu número Callmatik fuera: 1234512345',
    dataTwelve: 'ACTIVAR, marcar *67*1234512345# y pulsar llamada 📞.',
    dataThirteen: 'DESACTIVAR, sólo marca #67# y pulsar llamada 📞.',
    dataFourteen: '',
    dataFiveteen: 'Activar solo cuando no contestas (tras 20 segundos*):',
    dataSixteen: 'Marca en tu celular o fijo *61*TúNúmeroCallMatik #.',
    dataSeventeen: 'Ejemplo: Si tu número Callmatik fuera: 1234512345',
    dataEighteen: 'ACTIVAR, marcar *61*1234512345# y pulsar llamada 📞.',
    dataNineteen: 'DESACTIVAR, sólo marca #61# y pulsar llamada 📞.',
    dataTwenty: '* Puedes contestar tu teléfono hasta 20 segundos antes de que la llamada sea transferida. Si tienes buzón de voz, debes cuidar que la cantidad de timbrados para que entre sea mayor a los 20 segundos para que se transfiera, de lo contrario entrará el buzón.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Qué son las notificaciones?',
    dataSecond: 'Significa que para cada llamada te enviaremos un email con los detalles de dicha  llamada. También podrás  verlo siempre en el panel de control de tu área privada.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Qué son las Llamadas Adicionales?',
    dataSecond: 'Son las llamadas adicionales en el mes a tu plan. Por ejemplo si tú plan es de 30 llamadas y tienes un mes 35 llamadas. Las 5 llamadas adicionales son las que tendrían el precio marcado para las llamadas adicionales en tu plan.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Qué son las Agendas Adicionales?',
    dataSecond: 'A partir del programa PREMIUM tienes el servicio de gestión de agenda. Este sería el coste de cada gestión de Agenda.',
    show: false,
    status: true,
  },
  {
    icon: null,
    dataFirst: '¿Qué son las llamadas salientes?',
    dataSecond: 'Ahora con tus planes puedes disfrutar de llamadas salientes. Nos envías los contactos y realizamos las llamadas en tu nombre.',
    //dataSecond: 'A partir del programa PLUS tienes el servicio adicional de llamadas salientes. Quiere decir que podemos hacer llamadas para determinados avisos o tipos de llamadas entrantes que tu definas.',
    //dataThird: 'Por ejemplo: Estamos dando servicio a un Pediatra. Puede indicarnos que si la llamada es de una mujer que este parto con contracciones. Indiquemos se dirija al hospital xxxx o el más cercano y que vamos a llamar de forma urgente al Doctor xxxx (pediatra). Tras la llamada que en ese caso llamemos de forma especial a su teléfono privado (pediatra) xxxxxxxxxx.',
    show: false,
    status: true,
  },
];



export const Faqs = () => {
  const [faqs, setFaqs] = useState(arFaqs);

  return (
    <ContFaqs id={'faqs'}>
      <Container isFluid={true}>
        <Columns className={'full-height'} isMultiline={true}>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 1, widescreen: 2 }}>
            &nbsp;
          </Column>
          <Column className={'center-column'} isSize={{ mobile: 12, tablet: 12, desktop: 10, widescreen: 8 }}>
            <Columns className={'full-height'} isMultiline={true}>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <TitleSection className={'animate__animated animate__fadeInUp'}>Preguntas <span>Frecuentes</span></TitleSection>
                <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                  <PieTitleSection className={'animate__animated animate__fadeInDown'}>¡No te quedes con la duda! Trabajamos para hacerte la vida más fácil</PieTitleSection>
                </Column>
              </Column>
            </Columns>
          </Column>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 1, widescreen: 2 }}>
            &nbsp;
          </Column>
        </Columns>
        <Columns className={'full-height'} isMultiline={true}>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 1, widescreen: 1 }}>
            &nbsp;
          </Column>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 10, widescreen: 10 }}>
            <Columns className={'full-height animate__animated animate__fadeInDown'} isMultiline={true}>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <AccordionMyS data={faqs} limit={5} />
              </Column>
            </Columns>
          </Column>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 1, widescreen: 1 }}>
            &nbsp;
          </Column>
        </Columns>
        <Columns className={'full-height'} isMultiline={true}>
          <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
            <div style={{textAlign: 'center', margin: '0 auto'}}>
              <ContBtn>
                <ABtnPrimaryTwo
                  cover
                  //bg={GlobalBackgroundColors.bgContentLight}
                  to={'/faqs'}
                  target="_self"
                >
                  Ver más +
              </ABtnPrimaryTwo>
            </ContBtn>
            </div>
            </Column>
          </Columns>
      </Container>
    </ContFaqs>
  )
}
