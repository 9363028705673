import {useRef, useState} from 'react';

export const useInputText = (
  name,
  value,
  requiredField,
  keyboardTypePassword,
  secureTextEntry,
  validateInput,
) => {
  const TOP_FOCUS = '-8px';
  const TOP_DEFAULT = '23px';
  const [secure, setSecure] = useState(secureTextEntry);
  const [focus, setFocus] = useState(false);
  //const [validateInput] = useState<boolean>(valInput);
  const [error, setError] = useState(false);
  const [showError, setShowError] = useState(false);

  let top = 0;
  const topFocus = '-11px';
  const topBlur = '20px';

  const showPass = () => {
    setSecure(!secure);
    //console.log('showPass => ', secure);
    // secure ? setOjo(imgOjoCerrado) : setOjo(imgOjoAbierto);
  };
  const onCopyValue = () => {
    // Clipboard.setString(value);
  };

  const onFocus = () => {
    console.log('onFocus => ', name);
    top = topFocus;
    setFocus(true);
    moveLabel(true);
  };

  const onBlur = () => {
    console.log('onBlur => ', name);
    top = topBlur
    setFocus(false);
    if (!value) {
      moveLabel(false);
    }
    if (validateInput) {
      onValidateInput();
    }
  };

  const moveLabel = (f) => {
    console.log('moveLabel ');
    const newTop = f || value ? TOP_FOCUS : TOP_DEFAULT;

    //setTop(paddingTop);
  };

  const onValidateInput = () => {
    let ERR;

    if(requiredField === true){
      if (value === '' || value === undefined) {
        setError('El campo no puede estar vacío');
        setShowError(true);
        return;
      } else {
        setShowError(false);
      }
    } else {
      setShowError(false);
    }
    //console.log(`onValidate => ${type} - ${value}`);
  };

  return {
    top,
    topFocus,
    topBlur,
    secure,
    focus,
    error,
    showError,
    onFocus,
    onBlur,
  };
};
