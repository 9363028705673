import styled, { createGlobalStyle } from 'styled-components'
import {GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const StylesSolutions = createGlobalStyle`
  .margin-top{
    @media(min-width: 300px) and (max-width: 767px){
      margin-top: 50px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      margin-top: 50px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      margin-top: 55px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      margin-top: 100px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      margin-top: 100px;
    }
    @media(min-width: 1920px){
      margin-top: 100px;
    }
  }
`
export const ContSolutions = styled.div`
  width: 100%;
  @media(min-width: 300px) and (max-width: 767px){
    margin-top: 132px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-top: 132px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-top: 132px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-top: 155px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    margin-top: 132px;
  }
  @media(min-width: 1920px){
    margin-top: 132px;
  }
`
export const ContSolutionsInfot = styled.div`
  @media(min-width: 300px) and (max-width: 767px){
    margin-top: 10px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-top: 10px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-top: 15px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-top: 10px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    margin-top: 10px;
  }
  @media(min-width: 1920px){
    margin-top: 10px;
  }
`
export const ContSolutionsItem = styled.div`
  position: relative;
  width: 90%;
  height: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 100%;
    margin-top: 7%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 100%;
    margin-top: 5%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 100%;
    margin-top: 9%;
  }
  @media(min-width: 1920px){
    width: 90%;
    margin-top: 7%;
  }
`
export const ContSolutionsImg = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`
export const ContInfo = styled.div`
  width: 100%;
  margin-bottom: 16px;
  display: flex;
  align-items: flex-start;
  justify-items: flex-start;
  flex-direction: row;
`
export const Ico = styled.div`
  width: 54px;
  height: 54px;
  &>img{
    width: 100%;
    height: 100%;
  }
  @media(min-width: 300px) and (max-width: 1023px){
    width: 33px;
    height: 33px;
  }
  @media(min-width: 1024px){
    width: 54px;
    height: 54px;
  }
`
export const ContTextInfo = styled.div`
  width: 100%;
`
export const Info = styled.div`
  width: 100%;
  margin: 0 15px 15px 15px;
  font: 23px ${GlobalFonts.fontBold};
  font-style: bold;
  font-weight: 800;
  line-height: 34px;
  color: ${GlobalColors.colorPrimary};
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 24px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 24px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 24px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 28px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 30px;
  }
  @media(min-width: 1920px){
    font-size: 30px;
  }
`
export const SubInfo = styled.div`
  width: 100%;
  margin: 0px 0px 15px 15px;
  font: 16px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${GlobalColors.colorText};
  
  @media(min-width: 300px) and (max-width: 767px){
    width: auto;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: auto;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 95%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 95%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 95%;
  }
  @media(min-width: 1920px){
    width: 95%;
  }
`
