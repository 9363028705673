import styled, { createGlobalStyle } from 'styled-components'
import {GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const StylesWhy = createGlobalStyle`
  .rec-carousel-wrapper-carousel{
    top: 0 !important;
  }
  .button-arrow-carousel{
    font-size: 40px;
    color: ${GlobalColors.colorMenuPrimary};
    cursor: pointer;
  }
  .cont-slider-arrow-carousel-l{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
  }
  .cont-slider-arrow-carousel-r{
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
  }
  .rec-pagination{
    @media(min-width: 300px) and (max-width: 767px){
      margin-top: 40px;
    }
    @media(min-width: 768px) and (max-width: 1023px){
      margin-top: 44px;
    }
    @media(min-width: 1024px) and (max-width: 1215px){
      margin-top: 46px;
    }
    @media(min-width: 1216px) and (max-width: 1407px){
      margin-top: 48px;
    }
    @media(min-width: 1408px) and (max-width: 1919px){
      margin-top: 50px;
    }
    @media(min-width: 1920px){
      margin-top: 50px;
    }
  }
  .rec-dot{
    box-sizing: border-box;
    padding: 0;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    border: none;
    margin: 5px;
    background-color: ${GlobalColors.colorTextDiscreet};
    font-size: 1.3em;
    content: "";
    height: 10px;
    width: 10px;
    box-shadow: 0 0 1px 2px ${GlobalColors.colorTextDiscreet};
    border-radius: 50%;
    outline: none;
  }
  .rec-dot_active{
    box-sizing: border-box;
    padding: 0;
    -webkit-transition: all 250ms ease;
    transition: all 250ms ease;
    border: none;
    margin: 5px;
    background-color: ${GlobalColors.colorBorder};
    font-size: 1.3em;
    content: "";
    height: 10px;
    width: 10px;
    box-shadow: 0 0 1px 3px ${GlobalColors.colorBorder};
    border-radius: 50%;
    outline: none;
  }
`
export const ContWhy = styled.div`
  width: 100%;
`
export const ContWhyImg = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ContWhyItem = styled.div`
  position: relative;
  width: 90%;
  height: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const WhyItemTitle = styled.div`
  width: 100%;
  font: 56px ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorPrimary};
  font-style: normal;
  font-weight: 800;
  line-height: 46px;
  text-align: left;
  
  &>span{
    color: ${GlobalColors.colorSecondary};
  }
  
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 20px;
    font-size: 28px;
    line-height: 28px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 22px;
    font-size: 30px;
    line-height: 30px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 32px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-bottom: 26px;
    font-size: 34px;
    line-height: 34px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    margin-bottom: 28px;
    font-size: 36px;
    line-height: 36px;
  }
  @media(min-width: 1920px){
    margin-bottom: 30px;
    font-size: 36px;
    line-height: 36px;
  }
`
export const WhyItemText = styled.div`
  width: 100%;
  font: 16px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorText};
`
