import React from 'react';
import {ContCheckboxMyS, Input, Label, Span} from './styles';
import {GlobalBackgroundColors, GlobalColors} from '../../../styles/GlobalStyles';

export const CheckboxMyS = ({
  label = '',
  checked = false,
  onChange,
  fontColor = GlobalColors.colorText,
  borderColor = GlobalBackgroundColors.bgTertiary,
  bgColorChecked = GlobalBackgroundColors.bgTertiary
}) => {

  return (
    <ContCheckboxMyS>
      <Label color={fontColor} bgColorChecked={bgColorChecked}>
        <Input
          type="checkbox"
          checked={checked}
          onChange={() => onChange()}
        />
        <Span borderColor={borderColor}/>
        {label}
      </Label>
    </ContCheckboxMyS>
  );
};
