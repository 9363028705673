import React, { useState } from 'react';
import { Column, Columns, Container } from 'bloomer';
import { RangeStepInput } from 'react-range-step-input';
import {
  BoxCounter, BoxCounterCant, BoxCounterLabel, ContCardFirst,
  ContConfigPlan,
  LabelItemConfigPlan,
  StylesConfigPlan,
  TitlePersonalizaPlan,
} from './styles';
import { CardPrice } from '../CardPrice';
import { PieTitleSection, TitleSection } from '../../styles/GlobalStyles';

export const ConfigPlan = () => {
  const [cantCalls, setCantCalls] = useState(0);
  const [serviceTime, setServiceTime] = useState(0);
  const [configPlans, setConfigPlans] = useState({
    textRangeStep: 0,
    citas: '0',
    citasRange: 0,
    citasRangeInfo: 'Plan sin opción para "Agendar citas"',
    name: 'PRO',
    subTitle: '',
    info1: 'Llamadas Entrantes y Salientes con el nombre de tu empresa.',
    info2: 'Recepción del nombre del llamante, su número de teléfono y motivo de la llamada.',
    info3: 'Posibilidad de gestionar agendas.',
    price: '499',
    detalles: [
      '30 llamadas.',
      'Llamada adicional: $19.00 MXM',
      'Sin opción para agendar citas.'
    ]
  });

  const forceNumber = function (n) {
    n = Number(n);
    if (isNaN(n) || typeof n === 'undefined') {
      n = 0;
    }
    return n;
  };
  const onChangeCantCall = (e) => {
    let newVal = forceNumber(e.target.value);
    setCantCalls(newVal);
    switch (true) {
      case (newVal < 30):
        setServiceTime(0);
        setConfigPlans({
          textRangeStep: 0,
          citas: '0',
          citasRange: 0,
          citasRangeInfo: 'Plan sin opción para "Agendar citas"',
          name: 'PRO',
          subTitle: '',
          info1: 'Llamadas Entrantes y Salientes con el nombre de tu empresa.',
          info2: 'Recepción del nombre del llamante, su número de teléfono y motivo de la llamada.',
          info3: 'Posibilidad de gestionar agendas.',
          price: '499',
          detalles: [
            '30 llamadas.',
            'Llamada adicional: $19.00 MXM',
            'Sin opción para agendar citas.'
          ]
        });
        break;
      case (newVal <= 60):
        setServiceTime(0);
        setConfigPlans({
          textRangeStep: 0,
          citas: '0',
          citasRange: 0,
          citasRangeInfo: 'Agenda de citas (hasta 30)',
          name: 'PREMIUM',
          subTitle: '',
          info1: 'Llamadas Entrantes y Salientes con el nombre de tu empresa.',
          info2: 'Recepción del nombre del llamante, su número de teléfono y motivo de la llamada.',
          info3: 'Posibilidad de gestionar agendas.',
          price: '899',
          detalles: [
            'Hasta 60 llamadas. Adicional: $17.00 MXN.',
            //'Sin llamada saliente para confirmación de cita agendada.',
            'Agenda adicional: $19.00 MXN',
          ]
        });

        break;
      case (newVal <= 100):
        if (newVal > 99) {
          setCantCalls(100);
        }
        setServiceTime(10);
        setConfigPlans({
          textRangeStep: 0,
          citas: '10',
          citasRange: 10,
          citasRangeInfo: '+ Pack adicional de hasta 10 citas agendadas',
          //name: 'PREMIUM +',
          name: 'PLUS',
          subTitle: '',
          info1: 'Llamadas Entrantes y Salientes con el nombre de tu empresa.',
          info2: 'Recepción del nombre del llamante, su número de teléfono y motivo de la llamada.',
          info3: 'Posibilidad de gestionar agendas.',
          price: '1,199',
          detalles: [
            '100 llamadas + 10 citas agendadas.',
            'Llamada adicional: $13.00 MXN',
            'Cita agendada adicional: $19.00 MXN',
            //'Llamada saliente para confirmación de cita: $62.00 MXN',
            '+ Pack adicional de hasta 10 citas agendadas.'
          ]
        });
        break;
      default:

    }

  };
  const onChangeServicetime = (e) => {
    const newVal = forceNumber(e.target.value);
    setServiceTime(newVal);
    setConfigPlans({ ...configPlans, citas: (newVal) })
    if (cantCalls <= 10 && newVal < 10) {
      setConfigPlans({
        textRangeStep: 0,
        citas: newVal,
        citasRange: 0,
        citasRangeInfo: 'Agenda de citas (hasta 30)',
        name: 'PREMIUM',
        subTitle: '',
        info1: 'Llamadas Entrantes y Salientes con el nombre de tu empresa.',
        info2: 'Recepción del nombre del llamante, su número de teléfono y motivo de la llamada.',
        info3: 'Posibilidad de gestionar agendas.',
        price: '899',
        detalles: [
          'Hasta 60 llamadas. Adicional: $17.00 MXN.',
          //'Sin llamada saliente para confirmación de cita agendada.',
        ]
      });
    }
    if (cantCalls <= 10 && newVal > 11) {
      setConfigPlans({
        textRangeStep: 0,
        citas: newVal,
        citasRange: 10,
        citasRangeInfo: '+ Pack adicional de hasta 10 citas agendadas',
        //name: 'PREMIUM +',
        name: 'PLUS',
        subTitle: '',
        info1: 'Llamadas Entrantes y Salientes con el nombre de tu empresa.',
        info2: 'Recepción del nombre del llamante, su número de teléfono y motivo de la llamada.',
        info3: 'Posibilidad de gestionar agendas.',
        price: '1,199',
        detalles: [
          '100 llamadas + 10 citas agendadas.',
          'Llamada adicional: $13.00 MXN',
          'Cita agendada adicional: $19.00 MXN',
          //'Llamada saliente para confirmación de cita: $62.00 MXN',
          '+ Pack adicional de hasta 10 citas agendadas.'
        ]
      });
    }
    if (cantCalls <= 100 && newVal > 11) {
      setConfigPlans({
        textRangeStep: 0,
        citas: newVal,
        citasRange: 10,
        citasRangeInfo: '+ Pack adicional de hasta 10 citas agendadas',
        //name: 'PREMIUM +',
        name: 'PLUS',
        subTitle: '',
        info1: 'Llamadas Entrantes y Salientes con el nombre de tu empresa.',
        info2: 'Recepción del nombre del llamante, su número de teléfono y motivo de la llamada.',
        info3: 'Posibilidad de gestionar agendas.',
        price: '1,199',
        detalles: [
          '100 llamadas + 10 citas agendadas.',
          'Llamada adicional: $13.00 MXN',
          'Cita agendada adicional: $19.00 MXN',
          //'Llamada saliente para confirmación de cita: $62.00 MXN',
          '+ Pack adicional de hasta 10 citas agendadas.'
        ]
      });
    }
    if (cantCalls >= 10 && cantCalls <= 100 && newVal < 11) {
      setConfigPlans({
        textRangeStep: 0,
        citas: newVal,
        citasRange: 0,
        citasRangeInfo: 'Agenda de citas (hasta 30)',
        name: 'PREMIUM',
        subTitle: '',
        info1: 'Llamadas Entrantes y Salientes con el nombre de tu empresa.',
        info2: 'Recepción del nombre del llamante, su número de teléfono y motivo de la llamada.',
        info3: 'Posibilidad de gestionar agendas.',
        price: '899',
        detalles: [
          'Hasta 60 llamadas. Adicional: $17.00 MXN.',
          //'Sin llamada saliente para confirmación de cita agendada.',
        ]
      });
    }
  };

  return (
    <ContConfigPlan id={'configplan'}>
      <StylesConfigPlan />
      <Container isFluid={true}>
        <Columns className={'full-height'} isMultiline={true}>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 2, widescreen: 3 }}>
            &nbsp;
          </Column>
          <Column className={'center-column'} isSize={{ mobile: 12, tablet: 12, desktop: 8, widescreen: 6 }}>
            <Columns className={'full-height'} isMultiline={true}>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <TitleSection className={'wow animate__animated animate__fadeInUp'}>Configura <span>tu plan</span></TitleSection>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <PieTitleSection className={'wow animate__animated animate__fadeInDown'}>
                  <div>Sabemos que cada persona es diferente.</div>
                  <div>¡Descubre el plan que se ajusta a tus necesidades!</div>
                </PieTitleSection>
              </Column>
            </Columns>
          </Column>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 2, widescreen: 3 }}>
            &nbsp;
          </Column>
        </Columns>

        <Columns className={'full-height'} isMultiline={true}>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 1, widescreen: 1 }}>
            &nbsp;
          </Column>
          <Column className={'center-column'} isSize={{ mobile: 12, tablet: 12, desktop: 10, widescreen: 10 }}>
            <Columns className={'full-height'}>
              <Column className={'padding-side-no wow animate__animated animate__fadeInLeft'} isSize={{ mobile: 12, tablet: 12, desktop: 6, widescreen: 6 }}>
                <Columns isMultiline={true}>
                  <Column className={'padding-side-no'} isSize={'full'}>
                    <TitlePersonalizaPlan>Personaliza tu plan</TitlePersonalizaPlan>
                  </Column>
                  <Column className={'padding-side-no'} isSize={'full'}>
                    <Columns>
                      <Column className={'padding-side-no vertical-center'} isSize={{ mobile: 8, tablet: 8, desktop: 8, widescreen: 8 }}>
                        <LabelItemConfigPlan>Cantidad de llamadas</LabelItemConfigPlan>
                      </Column>
                      <Column className={'padding-side-no'} isSize={{ mobile: 4, tablet: 4, desktop: 4, widescreen: 4 }}>
                        <BoxCounter>
                          <BoxCounterCant>{cantCalls}</BoxCounterCant>
                          <BoxCounterLabel>Llamadas</BoxCounterLabel>
                        </BoxCounter>
                      </Column>
                    </Columns>
                  </Column>
                  <Column className={'padding-side-no'} isSize={'full'}>
                    <RangeStepInput
                      min={0}
                      max={100}
                      step={1}
                      value={cantCalls}
                      onChange={(newVal) => onChangeCantCall(newVal)}
                    />
                  </Column>
                </Columns>
                <Columns isMultiline={true}>
                  <Column className={'padding-side-no'} isSize={'full'}>
                    <Columns>
                      <Column className={'padding-side-no vertical-center'} isSize={{ mobile: 8, tablet: 8, desktop: 8, widescreen: 8 }}>
                        <LabelItemConfigPlan>{configPlans.citasRangeInfo}</LabelItemConfigPlan>
                      </Column>
                      <Column className={'padding-side-no'} isSize={{ mobile: 4, tablet: 4, desktop: 4, widescreen: 4 }}>
                        <BoxCounter>
                          <BoxCounterCant>{configPlans.citas}</BoxCounterCant>
                          <BoxCounterLabel>Citas agendas</BoxCounterLabel>
                        </BoxCounter>
                      </Column>
                    </Columns>
                  </Column>
                  {/* {configPlans.citasRange == 0 ?
                    <div style={{background: '#7E7D7D', width: '100%', borderRadius: '50px', fontSize: '8px', color: '#7E7D7D'}}>
                      aaa
                    </div>
                  : */}
                  <Column className={'padding-side-no'} isSize={'full'}>
                    <RangeStepInput
                      min={1}
                      max={100}
                      step={1}
                      value={serviceTime}
                      onChange={(newVal) => onChangeServicetime(newVal)}
                    />

                  </Column>

                </Columns>
              </Column>
              <Column className={'padding-side-no wow animate__animated animate__fadeInRight'} isSize={{ mobile: 12, tablet: 12, desktop: 6, widescreen: 6 }}>
                <ContCardFirst>
                  <CardPrice
                    type={2}
                    title={'Plan Personalizado'}
                    name={configPlans.name}
                    subTitle={''}
                    info1={configPlans.info1}
                    info2={configPlans.info2}
                    info3={configPlans.info3}
                    price={configPlans.price}
                    DetalleTexts={configPlans.detalles}
                  />
                </ContCardFirst>
              </Column>
            </Columns>
          </Column>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 1, widescreen: 0 }}>
            &nbsp;
          </Column>
        </Columns>

      </Container>
    </ContConfigPlan>
  );
};
