import styled, {createGlobalStyle} from 'styled-components';
import {Button, Label} from "bloomer";
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from "../../styles/GlobalStyles";

export const GlobalStylesFormContact = createGlobalStyle`
  .align-button{
    text-align: center;    
  }
`

export const ContFormContacto = styled.div`
  position: relative;
  width: 100%;
  background-color: ${GlobalBackgroundColors.bgColorHeader};
  box-shadow: 0px 8px 28px -6px rgba(11, 107, 221, 0.12), 0px 18px 88px -4px rgba(11, 107, 221, 0.12);
  border-radius: 20px;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    padding: 30px 15px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
    padding: 30px 15px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 100%;
    padding: 30px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 80%;
    padding: 30px;
  }
  @media(min-width: 1408px){
    width: 80%;
    padding: 30px;
  }
`
export const ContSoscial = styled.div`
  text-align: center;
  margin: 0 auto;
`;
export const LabelBloomer = styled(Label)`
  margin-bottom: 20px;
    
  font-family: ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorLinkHover};
  text-align: left;
`
export const BtnSendBloomer = styled(Button)`
  font-family: ${GlobalFonts.fontMedium};
  background-color: ${GlobalBackgroundColors.bgPrimary};
  color: ${GlobalColors.colorSecondary};
`
export const ContMessage = styled.div`
  padding: 25px;
  font: 20px ${GlobalFonts.fontPrimary};
  color: ${GlobalColors.colorSecondary};
`
export const ContBtnContact = styled.div`
  position: relative;
  width: 100%;
  margin: 25px auto 0;
`

export const ButtonSocial = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  padding: 10;
  width: 100%;
  height: 54px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const GoogleButton = styled(ButtonSocial)`
  background-color: transparent;
  border: 2px solid #4285f4;
`;

export const FacebookButton = styled(ButtonSocial)`
  background-color: transparent;
  border: 2px solid #1877F2;
`;

export const Logo = styled.img`
  width: 30px; /* Tamaño del logo */
  height: 30px; /* Tamaño del logo */
  margin-right: 10px; /* Espacio entre el logo y el texto */
`;

export const ContinueTextFacebook = styled.span`
  color: #1877F2;
  font-size: 20px;
  font-family: ${GlobalFonts.fontExtraBold};
`;
export const ContinueTextGoogle = styled.span`
  color: #000000;
  opacity: 0.54;
  font-size: 20px;
  font-family: ${GlobalFonts.fontExtraBold};
`;

export const ContSeparatorLine = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const SeparatorLine = styled.div`
  display: flex;
  align-items: center;
  width: calc(50% - 10px);
  height: 1px;
  background-color: #BDBAE4;
`;

export const SeparatorLineText = styled.span`
  color: #5B52BC;
  font-size: 20px;
  font-family: ${GlobalFonts.fontExtraBold};
`;
