import styled, { createGlobalStyle } from 'styled-components'
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const StylesConfigPlan = createGlobalStyle`
  input[type='range'] {
    display: block;
    width: 100%;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range'],
  input[type='range']::-webkit-slider-runnable-track,
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }
  /* Circulo */
  input[type=range]::-webkit-slider-thumb {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    background-color: ${GlobalBackgroundColors.bgQuaternary};
    border-radius: 50%;
    box-shadow: 0px 3.81068px 13.3374px -2.85801px rgba(91, 82, 188, 0.25), 0px 8.57404px 41.9175px -1.90534px rgba(91, 82, 188, 0.2);
  }
  
  input[type=range]::-moz-range-thumb {
    width: 15px;
    height: 15px;
    margin-top: -10px;
    background-color: ${GlobalBackgroundColors.bgTertiary};
    border-radius: 50%;
    box-shadow: 0px 3.81068px 13.3374px -2.85801px rgba(91, 82, 188, 0.25), 0px 8.57404px 41.9175px -1.90534px rgba(91, 82, 188, 0.2);
  }

  input[type=range]::-ms-thumb {
    width: 15px;
    height: 15px;
    margin-top: -10px;
    background-color: ${GlobalBackgroundColors.bgTertiary};
    border-radius: 50%;
    box-shadow: 0px 3.81068px 13.3374px -2.85801px rgba(91, 82, 188, 0.25), 0px 8.57404px 41.9175px -1.90534px rgba(91, 82, 188, 0.2);
  }

  input[type=range]::-webkit-slider-runnable-track {
    height: 10px;
    background-color: ${GlobalBackgroundColors.bgTertiary};
    border-radius: 8.5px;
  }

  input[type=range]:focus::-webkit-slider-runnable-track {
    outline: none;
  }
  
  input[type=range]::-moz-range-track,
  input[type=range]::-ms-track {
    height: 30px;
    background-color: ${GlobalBackgroundColors.bgColorTextFound};
  }

  input[type=range]::-ms-fill-lower,
  input[type=range]::-ms-fill-upper {
    background-color: ${GlobalBackgroundColors.bgContentLightest};
  }

  #configplan .rec-pagination{
    margin-top: -50px;
  }
  #configplan .rec-slider-container{
    margin: 0 !important;
  }
`
export const ContConfigPlan = styled.div`
  margin-top: 130px;
  @media(min-width: 300px) and (max-width: 767px){
    margin-bottom: 90px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    margin-bottom: 100px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    margin-bottom: 120px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    margin-bottom: 130px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    margin-bottom: 150px;
  }
  @media(min-width: 1920px){
    margin-bottom: 150px;
  }
`
export const TitlePersonalizaPlan = styled.div`
  font: 36px ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorPrimary};
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 24px;
    text-align: center;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 24px;
    text-align: center;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 28px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 32px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 36px;
  }
  @media(min-width: 1920px){
    font-size: 36px;
  }
`
export const LabelItemConfigPlan = styled.div`
  font: 24px ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorPrimary};
  font-style: normal;
  font-weight: 800;
  line-height: 28px;

  @media(min-width: 300px) and (max-width: 767px){
    font-size: 18px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 20px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 20px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 24px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 24px;
  }
  @media(min-width: 1920px){
    font-size: 24px;
  }
`
export const BoxCounter = styled.div`
  width: 125px;
  height: 62px;
  background: ${GlobalBackgroundColors.bgTertiary};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
export const BoxCounterCant = styled.div`
  font: 28px ${GlobalFonts.fontBold};
  color: ${GlobalColors.colorBtnTextPrimary};
  font-style: normal;
  font-weight: 800;
  line-height: 36px;
`
export const BoxCounterLabel = styled.div`
  font: 14px ${GlobalFonts.fontRegular};
  color: ${GlobalColors.colorBtnTextPrimary};
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
`
export const ContCardFirst = styled.div`
  position: relative;
  width: 90%;
  height: auto;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media(min-width: 300px) and (max-width: 1023px){
    width: 100%;
    padding: 0 0px !important;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 95%;
    float: right;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 90%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 90%;
  }
  @media(min-width: 1920px){
    width: 75%;
  }
`
