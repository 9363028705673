import React, {useEffect, useState} from "react"
import axios from "axios"
import {
  Modal,
  ModalBackground,
  ModalContent,
  ModalClose,
  Column, Columns
} from "bloomer";
import {
  ContFormContacto, ContSoscial, ContMessage, GlobalStylesFormContact,
  GoogleButton, FacebookButton, Logo, ContinueTextFacebook, ContinueTextGoogle, 
  ContSeparatorLine, SeparatorLine, SeparatorLineText
} from "./stylesFormContact";
//import {LoginSocialFacebook} from "reactjs-social-login";
//import FacebookLogin from 'react-facebook-login';
//import { GoogleLogin, GoogleLogout } from 'react-google-login';
import {InputTextMyS} from '../ui/inputText/InputText';
import {BtnPrimary} from '../../styles/GlobalStyles';
import {CheckboxMyS} from '../ui/Checkbox';
import {ContBtnContact} from './stylesFormContact';
import {TextAreaMyS} from '../ui/TextArea';
//import GoogleIcon from '../../images/google-icon.png'; 
//import FacebookIcon from '../../images/facebook-icon.png';
//import {gapi} from "gapi-script";

const FormContactComp = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [contact, setContact] = useState({
    name: '',
    email: '',
    message: '',
    accept: false
  });

  const validateForm = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  
    if (!contact.name || !contact.email || !contact.message || !contact.accept) {
      document.getElementById('msg-content').innerHTML = 'Ingrese todos los datos correctamente';
      setShowMessage(true);
      return false;
    }
  
    if (!emailRegex.test(contact.email)) {
      document.getElementById('msg-content').innerHTML = 'Ingrese un correo electrónico válido';
      setShowMessage(true);
      return false;
    }
  
    return true;
  }

  const handleSendFormContact = () => {
    if (!validateForm()) {
      return;
    }
    const url = 'https://ir8yxddma8.execute-api.us-west-2.amazonaws.com/v1/email-contact';

    const bodyMail = {
      "subject": "Contacto Callmatik",
      "name": contact.name,
      "email": contact.email,
      "message": contact.message
    }

    axios({
      method: 'post',
      url: url,
      data: bodyMail
    }).then(function (response) {

      document.getElementById('msg-content').innerHTML = 'Correo enviado correctamente.';
      setShowMessage(true);
      setContact({
        name: '',
        email: '',
        message: '',
        accept: false
      });
    })
    .catch(function (error) {
      //console.log(error);
      document.getElementById('msg-content').innerHTML = error;
      setShowMessage(true);
    });
  
  }

  const onChangeName = val => {
    console.log('onChangeName => ', val);
    setContact({
      ...contact,
      name: val,
    });
  };
  const onChangeEmail = val => {
    console.log('onChangeEmail => ', val);
    setContact({
      ...contact,
      email: val,
    });
  };
  const onChangeMessage = val => {
    console.log('onChangeMessage => ', val);
    setContact({
      ...contact,
      message: val,
    });
  };
  const onAccept = () => {
    console.log('onAccept');
    const ACCEPT = !contact.accept;
    setContact({
      ...contact,
      accept: ACCEPT,
    });
  };

  const handleCloseMessage = () => {
    //console.log('handleCloseMessage');
    setShowMessage(false);
  };

  const responseFacebook = (response) => {
    console.log('responseFacebook?>', response);

  };

  const onSuccessGoogle = (response) => {
    console.log('response onSuccessGoogle =>', response);
  }

  const onFailureGoogle = (response) => {
    console.log('response onFailureGoogle=>', response);
  }

  const onSuccessLogoutGoogle = (response) => {
    console.log('response onSuccessLogoutGoogle=>', response);
  }

  const onFailureLogoutGoogle = (response) => {
    console.log('response onFailureLogoutGoogle=>', response);
  }


  //useEffect(() => {
  //  function start() {
  //    gapi.client.init({
  //      clientId: '243038856595-m9o6kgdh6skhrv1b2lr3pmastubibm2h.apps.googleusercontent.com',
  //      scope: '',
  //    })
  //  }
  //  gapi.load('client:auth2', start);
  //}, [])

  return(
    <ContFormContacto
      id={'ContFormContacto'}
      className={'animate__animated animate__fadeInRight'}
      style={{visibility: 'visible', animationDelay: '0.9s', animationName: 'fadeInUp'}}
    >
      <GlobalStylesFormContact/>
      {/*}
      <ContSoscial>
        <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
          <LoginSocialFacebook
              appId="281074761666703"
              onResolve={(response) => {
                responseFacebook(response);
              }}
              onReject={(error) => {
                console.log('error>', error);
              }}
            >
            <FacebookButton>
              <Logo src={FacebookIcon} alt="Facebook" />
              <ContinueTextFacebook>Continue with Facebook</ContinueTextFacebook>
            </FacebookButton>
          </LoginSocialFacebook>
          <GoogleButton>
            <GoogleLogin
              clientId="243038856595-m9o6kgdh6skhrv1b2lr3pmastubibm2h.apps.googleusercontent.com"
              buttonText="Continue with Google"
              onSuccess={onSuccessGoogle}
              onFailure={onFailureGoogle}
              cookiePolicy={'single_host_origin'}
              isSignedIn={true}
            />
            {/*
            <GoogleLogout
              clientId="243038856595-m9o6kgdh6skhrv1b2lr3pmastubibm2h.apps.googleusercontent.com"
              buttonText="Logout"
              onSuccess={onSuccessLogoutGoogle}
              onFailure={onFailureLogoutGoogle}
            />
            */}
            {/*
            <Logo src={GoogleIcon} alt="Google" />
            <ContinueTextGoogle>Continue with Google</ContinueTextGoogle>
          </GoogleButton>
        </Column>
        <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
          <ContSeparatorLine>
            <SeparatorLine/>
              <SeparatorLineText> O </SeparatorLineText> 
            <SeparatorLine />
          </ContSeparatorLine>
        </Column>
      </ContSoscial>
      */}

      <form id={'frmContact'} name={'frmContact'} >
        <Columns className="columns-responsive full-height">

          <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
            <div>
              <InputTextMyS
                name={'txtName'}
                label={'Nombre'}
                type={'text'}
                value={contact.name}
                onChange={e => onChangeName(e)}
                autoFocus={false}
                readOnly={false}
              />
            </div>
            <div>
              <InputTextMyS
                name={'txtEmail'}
                label={'E-mail'}
                type={'text'}
                value={contact.email}
                onChange={e => onChangeEmail(e)}
                autoFocus={false}
                readOnly={false}
              />
            </div>
            <div>
              <TextAreaMyS
                name={'txtMessage'}
                label={'Mensaje'}
                value={contact.message}
                height={'150px'}
                onChange={e => onChangeMessage(e.target.value)}
                autoFocus={false}
                readOnly={false}
              />
            </div>
            <div>
              <CheckboxMyS
                label={'Acepto Términos y Condiciones'}
                checked={contact.accept}
                onChange={() => onAccept()}
              />
            </div>
            <ContBtnContact>
              <BtnPrimary type="button" onClick={() => handleSendFormContact()}>
                Contactar
              </BtnPrimary>
            </ContBtnContact>
          </Column>
        </Columns>
      </form>

      <Modal isActive={showMessage}>
        <ModalBackground />
        <ModalContent>
          <ContMessage id="msg-content">&nbsp;</ContMessage>
        </ModalContent>
        <ModalClose onClick={() => handleCloseMessage()} />
      </Modal>

    </ContFormContacto>
  );
};

export default FormContactComp;
