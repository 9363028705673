import styled, {createGlobalStyle} from 'styled-components';
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from '../../../styles/GlobalStyles';
import {ContCardPrice} from '../../CardPrice/styles';

export const StylesWelcome = createGlobalStyle`
  
`
export const ContCheckboxMyS = styled.div`
  width: 100%;
  padding: 15px 0 15px 15px;
`
export const Label = styled.label`
  font: 16px ${GlobalFonts.fontPrimary};
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  color: ${props => props.color};

  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  
  &:hover input ~ span {
    background-color: #ccc;
  }
  & input:checked ~ span {
    //background-color: ${GlobalBackgroundColors.bgTertiary};
    background-color: ${props => props.bgColorChecked};
  }
  & input:checked ~ span:after {
    display: block;
  }

  ${ContCardPrice}:hover & {
    color: ${GlobalColors.colorLight};
  }
`
export const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`
export const Span = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  // border: 3px solid ${GlobalBackgroundColors.bgTertiary};
  border: 3px solid ${props => props.borderColor};
  border-radius: 4px;
  
  &:after {
    content: "";
    position: absolute;
    display: none;

    left: 7px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  ${ContCardPrice}:hover & {
    border: 3px solid ${GlobalBackgroundColors.bgQuaternary};
  }
`
