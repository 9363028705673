import React from 'react';
import {Column, Columns, Container} from 'bloomer';
import {ContInfoBubble, ContInfoBubbleInner, ContText, ContText1, ContText2} from './styles';
import {ABtnPrimary, BtnPrimary, GlobalBackgroundColors, GlobalStyle} from '../../styles/GlobalStyles';
import IconCheck from '../../images/icon-check.svg';

export const InfoBubble = () => {

  return (
    <ContInfoBubble>
      <GlobalStyle/>
      <Container className={'no-display-mobile'} isFluid={false}>
        <Columns className={'full-heightbar'} isMultiline={false}>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>
            &nbsp;
          </Column>
          <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 10, widescreen: 10}}>
            <ContInfoBubbleInner>
              <Columns className={'full-height'}>
                <Column className={'flex-center wow animate__animated animate__fadeInDown'} isSize={{mobile: 0, tablet: 0, desktop: 2, widescreen: 2}}>
                  <img src={IconCheck} alt={''}/>
                </Column>
                <Column isSize={{mobile: 0, tablet: 0, desktop: 7, widescreen: 7}}>
                  <ContText>
                    <ContText1 className={'wow animate__animated animate__fadeInDown'}>Disfruta de nuestro servicio</ContText1>
                    <ContText2 className={'wow animate__animated animate__fadeInUp'}>y conoce los beneficios que tenemos para ti.</ContText2>
                  </ContText>
                </Column>
                <Column className={'flex-center wow animate__animated animate__fadeInDown'} isSize={{mobile: 0, tablet: 0, desktop: 3, widescreen: 3}}>
                  <ABtnPrimary
                    cover
                    bg={GlobalBackgroundColors.bgContentLight}
                    to={'/registrate'}
                    target="_self"
                  >
                    Me interesa
                  </ABtnPrimary>
                </Column>
              </Columns>
            </ContInfoBubbleInner>
          </Column>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>
            &nbsp;
          </Column>
        </Columns>
      </Container>
    </ContInfoBubble>
  );
};
