import styled, { createGlobalStyle }  from 'styled-components'
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const GlobalStyleContact = createGlobalStyle`
  .container-inner-contact{
    padding-top: 50px !important;
  }
`
export const ContContact = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  margin: 0 auto;
  padding-bottom: 35px;
  background-color: ${GlobalBackgroundColors.bgTertiary};
  border-top-left-radius: 50px;
  border-top-right-radius: 50px;
`
export const ContDetailsContact = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
    margin-bottom: 30px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
    margin-bottom: 30px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 100%;
    margin-bottom: 60px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 100%;
    margin-bottom: 60px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 70%;
    margin-bottom: 60px;
    text-aling: center;
  }
  @media(min-width: 1920px){
    width: 70%;
    margin-bottom: 60px;
    text-aling: center;
  }
`
export const ContIcon = styled.div`
  width: 33px;
  margin-right: 15px;
`
export const ContInfoContact = styled.div`
  width: 100%;
  font: 18px ${GlobalFonts.fontRegular};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  color: ${GlobalColors.colorMenuMobilePrimary};
`
