import styled, { createGlobalStyle } from 'styled-components'
import {GlobalBackgroundColors, GlobalColors, GlobalFonts} from '../../styles/GlobalStyles';

export const StylesHome = createGlobalStyle`
  .center-column{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .right-column {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }
`
export const TextPurple = styled.div`
  font: 18px ${GlobalFonts.fontRegular};
  color: #5B52BC;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  
  @media(min-width: 300px) and (max-width: 767px){
  }
  @media(min-width: 768px) and (max-width: 1023px){
  }
  @media(min-width: 1024px) and (max-width: 1215px){
  }
  @media(min-width: 1216px) and (max-width: 1407px){
  }
  @media(min-width: 1408px) and (max-width: 1919px){
  }
  @media(min-width: 1920px) {
  }
`
export const NumberPurple = styled.div`
  font: 18px ${GlobalFonts.fontBold};
  color: #5B52BC;
  font-style: bold;
  font-weight: 800;
  line-height: 20px;
  
  @media(min-width: 300px) and (max-width: 767px){
  }
  @media(min-width: 768px) and (max-width: 1023px){
  }
  @media(min-width: 1024px) and (max-width: 1215px){
  }
  @media(min-width: 1216px) and (max-width: 1407px){
  }
  @media(min-width: 1408px) and (max-width: 1919px){
  }
  @media(min-width: 1920px) {
  }
`
export const ContHome = styled.div`
  padding-top: 10px;
  background-color: ${GlobalBackgroundColors.bgPrimary};
`
export const ContPhrase = styled.div`
  margin-bottom: 30px;
  flex-direction: row;
`
export const PhraseColor1 = styled.h1`
  font: 48px ${GlobalFonts.fontExtraBold};
  color: ${GlobalColors.colorPrimary};
  font-style: bold;
  font-weight: 800;
  line-height: 60px;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 38px;
    line-height: 38px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 42px;
    line-height: 42px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 36px;
    line-height: 38px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 40px;
    line-height: 56px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 46px;
    line-height: 60px;
  }
  @media(min-width: 1920px){
    font-size: 50px;
    line-height: 66px;
  }
`
export const PhraseColor2 = styled.span`
  font: 48px ${GlobalFonts.fontExtraBold};
  color: ${GlobalColors.colorSecondary};
  font-style: bold;
  font-weight: 800;
  line-height: 60px;

  @media(min-width: 300px) and (max-width: 767px){
    font-size: 38px;
    line-height: 38px;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 42px;
    line-height: 42px;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 36px;
    line-height: 38px;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 40px;
    line-height: 56px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 46px;
    line-height: 60px;
  }
  @media(min-width: 1920px){
    font-size: 50px;
    line-height: 66px;
  }
`
export const PhraseFooter = styled.div`
  margin-bottom: 40px;
  font: 24px ${GlobalFonts.fontPrimary};
  color: ${GlobalColors.colorText};
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  
  @media(min-width: 300px) and (max-width: 767px){
    font-size: 20px !important;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    font-size: 22px !important;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    font-size: 22px !important;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    font-size: 24px;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    font-size: 24px;
  }
  @media(min-width: 1920px){
    font-size: 24px;
  }
`
export const ContImgCover = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto 30px;
  padding-bottom: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  
  @media(min-width: 300px) and (max-width: 767px){
    width: 100%;
  }
  @media(min-width: 768px) and (max-width: 1023px){
    width: 100%;
  }
  @media(min-width: 1024px) and (max-width: 1215px){
    width: 100%;
  }
  @media(min-width: 1216px) and (max-width: 1407px){
    width: 100%;
  }
  @media(min-width: 1408px) and (max-width: 1919px){
    width: 100%;
  }
  @media(min-width: 1920px){
    width: 100%;
  }
`

export const ContNocuota = styled.div`

`;

export const TextOrange = styled.div`
  font: 20px ${GlobalFonts.fontBold};
  color: #FF8675;
  font-style: bold;
  font-weight: 600;
  line-height: 28px;
  text-align: center;
  margin: 0 auto;
  width: 300px;
  padding: 10px 20px;
  border-radius: 5px;
  background-color: #FFE7E3;
  
  @media(min-width: 300px) and (max-width: 767px){
  }
  @media(min-width: 768px) and (max-width: 1023px){
  }
  @media(min-width: 1024px) and (max-width: 1215px){
  }
  @media(min-width: 1216px) and (max-width: 1407px){
  }
  @media(min-width: 1408px) and (max-width: 1919px){
  }
  @media(min-width: 1920px) {
  }
`
