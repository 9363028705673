import React from 'react';
import {Column, Columns, Container} from 'bloomer';
import {
  ContSolutions,
  ContSolutionsImg,
  ContSolutionsItem,
  StylesSolutions,
  ContInfo,
  Ico,
  Info,
  SubInfo, ContTextInfo, ContSolutionsInfot,
} from './styles';
import {TitleSection} from '../../styles/GlobalStyles';
// import imgSolutions from '../../images/soluciones.png';
import imgSolutions from '../../images/solutions-cover.png';
import icoPhone from '../../images/ico-phone-second.svg';
import icoSend from '../../images/ico-send.svg';
import icoCalendar from '../../images/ico-calendar.svg';
import icoChat from '../../images/ico-chat.svg';

export const Solutions = () => {

  return (
    <ContSolutions id={'solutions'}>
      <StylesSolutions/>
      <Container isFluid={true}>
        <Columns className={'full-height'} isMultiline={true}>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 2, widescreen: 2}}>
            &nbsp;
          </Column>
          <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 8, widescreen: 8}}>
            <Columns className={'full-height'} isMultiline={true}>
              <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
                <TitleSection className={'wow animate__animated animate__fadeInUp'}>Soluciones <span>a tu medida</span></TitleSection>
              </Column>
            </Columns>
          </Column>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 2, widescreen: 2}}>
            &nbsp;
          </Column>
        </Columns>

        <Columns className={'full-height'} isMultiline={true}>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 1}}>
            &nbsp;
          </Column>
          <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 10, widescreen: 10}}>
            <ContSolutionsInfot>
              <Columns className={'full-height margin-top padding-side-no columns-reorder-mobile'} isMultiline={true}>
                <Column className={'center-column padding-side-no'} isSize={{mobile: 12, tablet: 12, desktop: 7, widescreen: 7}}>
                  <ContSolutionsItem>
                    <ContInfo className={'wow animate__animated animate__fadeInLeft'} style={{visibility: 'visible', animationDelay: '0.3s', animationName: 'fadeInLeft'}}>
                      <Ico><img src={icoPhone} alt={''}/></Ico>
                      <ContTextInfo>
                        <Info>Tendrás un número de teléfono solo para ti</Info>
                        <SubInfo>
                          Te asignamos un número de teléfono exclusivo para ti. Contamos con diferentes 
                          números para diferentes estados de México.
                        </SubInfo>
                      </ContTextInfo>
                    </ContInfo>

                    <ContInfo className={'wow animate__animated animate__fadeInLeft'} style={{visibility: 'visible', animationDelay: '0.6s', animationName: 'fadeInLeft'}}>
                      <Ico><img src={icoSend} alt={''}/></Ico>
                      <ContTextInfo>
                        <Info>Respondemos de forma personalizada</Info>
                        <SubInfo>
                          Tu secretaria contestará todas tus llamadas de forma personalizada, tu indicas que debe de decir en cada momento.
                        </SubInfo>
                      </ContTextInfo>
                    </ContInfo>

                    <ContInfo className={'wow animate__animated animate__fadeInLeft'} style={{visibility: 'visible', animationDelay: '0.9s', animationName: 'fadeInLeft'}}>
                      <Ico><img src={icoCalendar} alt={''}/></Ico>
                      <ContTextInfo>
                        <Info>Te notificamos tus llamadas en tiempo real</Info>
                        <SubInfo>
                          Tomamos tus recados y te notificaremos de inmediato por e-mail y sms toda la información: Nombre del llamante, Teléfono, E-mail, Motivo de la llamada u otra información que quieras que requiramos en tus llamadas.
                        </SubInfo>
                      </ContTextInfo>
                    </ContInfo>

                    <ContInfo className={'wow animate__animated animate__fadeInLeft'} style={{visibility: 'visible', animationDelay: '1.2s', animationName: 'fadeInLeft'}}>
                      <Ico><img src={icoChat} alt={''}/></Ico>
                      <ContTextInfo>
                        <Info>Agendamos tus citas</Info>
                        <SubInfo>
                          Las secretarias pueden gestionar tus citas directamente en tu calendario personal.
                        </SubInfo>
                        <SubInfo>
                          Atendemos tus llamadas de forma totalmente personalizada y de acuerdo a tus instrucciones. Como si estuviera en tu oficina. Olvídate de las respuestas genéricas y automatizadas!. Somos asistentes reales y profesionales a tu servicio.
                        </SubInfo>
                      </ContTextInfo>
                    </ContInfo>
                  </ContSolutionsItem>
                </Column>
                <Column className={'center-column padding-side-no'} isSize={{mobile: 12, tablet: 12, desktop: 5, widescreen: 5}}>
                  <ContSolutionsImg className={'wow animate__animated animate__fadeInRight'}>
                    <img src={imgSolutions} alt={''}/>
                  </ContSolutionsImg>
                </Column>
              </Columns>
            </ContSolutionsInfot>
          </Column>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 0}}>
            &nbsp;
          </Column>
        </Columns>


      </Container>
    </ContSolutions>
  );
};
