import React from 'react';
import {Column, Columns, Container} from 'bloomer';
import Carousel, {consts} from 'react-elastic-carousel';
import {PieTitleSection, TitleSection} from '../../styles/GlobalStyles';
import {ContWhy, ContWhyImg, ContWhyItem, StylesWhy, WhyItemText, WhyItemTitle} from './styles';
import ImgArrowLeft from '../../images/arrow-left.svg';
import ImgArrowRight from '../../images/arrow-right.svg';
import img1 from '../../images/slider-1B.png';
import img2 from '../../images/slider-2B.png';
import img3 from '../../images/slider-3B.png';

export const WhyItem = ({title1, title2, text, image}) => {
  return (
    <Columns className={'full-height'} isMultiline={true}>
      <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
        <ContWhyImg>
          <img src={image} alt={''}/>
        </ContWhyImg>
      </Column>
      <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
        <ContWhyItem>
          <WhyItemTitle>{title1} <span>{title2}</span></WhyItemTitle>
          <WhyItemText>
            {text}
          </WhyItemText>
        </ContWhyItem>
      </Column>
    </Columns>
  )
}

export const Why = () => {
  const myArrow = ({type, onClick, isEdge}) => {
    const pointer = type === consts.PREV
      ? <img src={ImgArrowLeft} alt={''}/>
      : <img src={ImgArrowRight} alt={''}/>

    const side = type === consts.PREV ? 'l' : 'r';

    return (
      <div className={`cont-slider-arrow-carousel-${side}`}>
        <button onClick={onClick} disabled={isEdge} className={'button-arrow-carousel'}>
          {pointer}
        </button>
      </div>
    );
  };

  return (
    <ContWhy id={'why'}>
      <StylesWhy/>
      <Container isFluid={true}>
        <Columns className={'full-height'} isMultiline={true}>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 2, widescreen: 2}}>
            &nbsp;
          </Column>
          <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 8, widescreen: 8}}>
            <Columns className={'full-height'} isMultiline={true}>
              <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
                <TitleSection className={'wow animate__animated animate__fadeInUp'}>¿Por qué <span>Callmatik?</span></TitleSection>
              </Column>
              <Column isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
                <PieTitleSection className={'wow animate__animated animate__fadeInDown'}>
                  <div>Somos una empresa dedicada a la gestión profesional y eficiente de la comunicación.</div>
                  <div>¡Sin importar a lo que te dediques, Callmatik es para ti!</div>
                </PieTitleSection>
              </Column>
            </Columns>
          </Column>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 2, widescreen: 2}}>
            &nbsp;
          </Column>
        </Columns>

        <Columns className={'full-height'} isMultiline={true}>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 2}}>
            &nbsp;
          </Column>
          <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 10, widescreen: 8}}>
            <Carousel
              isRTL={false}
              itemsToScroll={1}
              itemsToShow={1}
              itemPadding={[0, 0]}
              renderArrow={myArrow}
              outerSpacing={0}
              itemPosition={consts.START}
              className={'rec-carousel-wrapper-carousel-in-modal'}
            >
              <div id={1}>
                <WhyItem
                  title1={'Atención 100%'}
                  title2={'Personalizada'}
                  text={'Nuestros asistentes personales responderán y realizarán tus llamadas con un trato profesional y respuestas personalizadas a tus necesidades.'}
                  image={img1}
                />
              </div>
              <div id={2}>
                <WhyItem
                  title1={'Organización al alcance'}
                  title2={'de tu mano'}
                  text={'Gestiona juntas, confirma reuniones y obtén los recados de las llamadas que no puedas responder sin dejar de hacer lo que estás haciendo y a un precio que te sorprenderá.'}
                  image={img2}
                />
              </div>
              <div id={3}>
                <WhyItem
                  title1={'Cero'}
                  title2={'complicaciones'}
                  text={'Aprovecha todas las ventajas de un servicio de asistencia personal, sin gastos de oficina, sin enredos de contrataciones y con horarios ampliados.'}
                  image={img3}
                />
              </div>
            </Carousel>
          </Column>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 1, widescreen: 2}}>
            &nbsp;
          </Column>
        </Columns>
      </Container>
    </ContWhy>
  );
};
