import React from 'react';
import { Column, Columns, Container } from 'bloomer';
import { GlobalColors, PieTitleSectionWhite, TitleSection } from '../../styles/GlobalStyles';
import {
  GlobalStyleContact,
  ContContact, ContIcon, ContInfoContact, ContDetailsContact,
} from './styles';
import FormContactComp from './formContact';
import IconPlus from '../../images/icon-plus-simple.svg';

const ContactComp = () => {

  return (
    <ContContact id={'contact'}>
      <GlobalStyleContact />
      <Container isFluid={true}>
        <Columns className={'full-height'} isMultiline={true}>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 1, widescreen: 2 }}>
            &nbsp;
          </Column>
          <Column className={'center-column'} isSize={{ mobile: 12, tablet: 12, desktop: 10, widescreen: 8 }}>
            <Columns className={'full-height'} isMultiline={true}>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <TitleSection 
                  className={'animate__animated animate__fadeInUp'}
                  color={GlobalColors.colorMenuMobilePrimary}>
                    Contáctanos
                </TitleSection>
              </Column>
              <Column isSize={{ mobile: 12, tablet: 12, desktop: 12, widescreen: 12 }}>
                <PieTitleSectionWhite className={'wow animate__animated animate__fadeInDown'}>
                  <div>Descubre todos los beneficios incluidos con nuestras </div>
                  <div>membresías y cancela en el momento que tu desees.</div>
                </PieTitleSectionWhite>
              </Column>
            </Columns>
          </Column>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 1, widescreen: 2 }}>
            &nbsp;
          </Column>
        </Columns>
        <Columns className={'full-height container-inner-contact'} isMultiline={true}>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 1, widescreen: 1 }}>
            &nbsp;
          </Column>
          <Column className={'center-column'} isSize={{ mobile: 12, tablet: 12, desktop: 5, widescreen: 5 }}>
            <ContDetailsContact className={'animate__animated animate__fadeInLeft'}>
              <ContIcon><img src={IconPlus} alt={'Plus'} /></ContIcon>
              <ContInfoContact>Tendrás un número de teléfono solo para ti</ContInfoContact>
            </ContDetailsContact>
            <ContDetailsContact className={'animate__animated animate__fadeInLeft'}>
              <ContIcon><img src={IconPlus} alt={'Plus2'} /></ContIcon>
              <ContInfoContact>Respondemos de forma personalizada</ContInfoContact>
            </ContDetailsContact>
            <ContDetailsContact className={'animate__animated animate__fadeInLeft'}>
              <ContIcon><img src={IconPlus} alt={'Plus3'} /></ContIcon>
              <ContInfoContact>Te notificamos tus llamadas en tiempo real</ContInfoContact>
            </ContDetailsContact>
            <ContDetailsContact className={'animate__animated animate__fadeInLeft'}>
              <ContIcon><img src={IconPlus} alt={'Plus3'} /></ContIcon>
              <ContInfoContact>Agendamos tus citas</ContInfoContact>
            </ContDetailsContact>
          </Column>
          <Column className={'center-column'} isSize={{ mobile: 12, tablet: 12, desktop: 6, widescreen: 6 }}>
            <FormContactComp />
          </Column>
          <Column className={'no-display-mobile'} isSize={{ mobile: 0, tablet: 0, desktop: 1, widescreen: 1 }}>
            &nbsp;
          </Column>
        </Columns>
      </Container>
    </ContContact>
  );
};

export default ContactComp;
