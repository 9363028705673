import React from 'react';
import {Column, Columns, Container} from 'bloomer';
import CoverHome from '../../images/cover-home.png';
import {ContHome, ContImgCover, ContPhrase, TextPurple, NumberPurple, TextOrange,
  PhraseColor1, PhraseColor2, PhraseFooter, StylesHome, ContNocuota} from './styles';
import {
  ABtnPrimary,
  ContIntoCellVL,
  GlobalBackgroundColors,
  GlobalStyle,
} from '../../styles/GlobalStyles';
import VideoCallMatik from '../VideoComponent/VideoCallMatik.mp4'


export const Home = () => {

  return (
    <ContHome>
      <GlobalStyle/>
      <StylesHome/>
      <Container>
        <Column className={'right-column'} isSize={{mobile: 0, tablet: 0, desktop: 0, widescreen: 0}}>
          <div style={{display: 'flex', gap: 20}}>
            <TextPurple>Contáctanos:</TextPurple> <NumberPurple>5534543678</NumberPurple>
          </div>
        </Column>
      </Container>
      <Container>
        <Columns>
          <Column className={'no-display-mobile'} isSize={{mobile: 0, tablet: 0, desktop: 0, widescreen: 0}}>
            &nbsp;
          </Column>
          <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 5}}>
            <ContIntoCellVL>
              <ContPhrase className={'wow animate__animated animate__fadeInLeft'}>
                <PhraseColor1>¡No pierdas llamadas importantes!</PhraseColor1>
                <PhraseColor2>Tu secretaria Callmatik las atiende por ti!</PhraseColor2>
              </ContPhrase>
              <PhraseFooter className={'wow animate__animated animate__fadeInLeft'}>
                <div>
                  Sin robots, sin buzones de voz, sin llamadas perdidas… &nbsp;
                  Profesionales reales a tu servicio y sin complicaciones.
                </div>
              </PhraseFooter>
              <div className={'wow animate__animated animate__fadeInLeft'}>
                <ABtnPrimary
                  cover
                  bg={GlobalBackgroundColors.bgContentLight}
                  to={'/registrate'}
                  target="_self"
                >
                  Más información 
                </ABtnPrimary>
              </div>
            </ContIntoCellVL>
          </Column>
          <Column isSize={{mobile: 12, tablet: 12, desktop: 6, widescreen: 6}}>
            <ContImgCover className={'wow animate__animated animate__fadeInUp has-text-centered'}>
              <video controls>
                <source src={VideoCallMatik} type="video/mp4" />
              </video>
            </ContImgCover>
          </Column>
        </Columns>
      </Container>
      <Container>
        <Column className={'center-column'} isSize={{mobile: 12, tablet: 12, desktop: 12, widescreen: 12}}>
          <ContNocuota>
            <TextOrange>¡Ahora sin cuota de alta!</TextOrange>
          </ContNocuota>
        </Column>
      </Container>
    </ContHome>
  );
};
